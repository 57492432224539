import twitter from './assets/images/twitter.png';
import discord from './assets/images/discord.png';
import mail from './assets/images/mail.png';
import URLS_MAP from './config/urls';

interface Link {
  iconSrc: string;
  link: string;
}

const links: Link[] = [
  // {
  //   iconSrc: facebook,
  //   link: 'https://www.facebook.com/duetprotocol',
  // },
  {
    iconSrc: twitter,
    link: 'https://twitter.com/duetprotocol',
  },
  // {
  //   iconSrc: medium,
  //   link: 'https://duetprotocol.medium.com',
  // },
  // {
  //   iconSrc: telegram,
  //   link: 'https://t.me/duetprotocol',
  // },
  // {
  //   iconSrc: weibo,
  //   link: 'https://weibo.com/u/7582624963',
  // },
  {
    iconSrc: discord,
    link: 'https://discord.gg/duetfinance',
  },
  {
    iconSrc: mail,
    link: 'mailto:contact@duet.finance',
  },
]

export interface SubMenu {
  icon: string;
  text: string;
  link: string;
}

export const communityMenus: SubMenu[] = [
    {
      icon: 'icon-twitter',
      link: URLS_MAP.twitter,
      text: 'Twitter'
    },
    {
      icon: 'icon-discord',
      link: URLS_MAP.discord,
      text: 'Discord'
    },
    // {
    //   icon: 'icon-telegram',
    //   link: URLS_MAP.telegram,
    //   text: 'Telegram'
    // },
    {
      icon: 'icon-medium',
      link: URLS_MAP.medium,
      text: 'Medium'
    },
    {
      icon: 'icon-YouTube',
      link: URLS_MAP.youtube,
      text: 'Youtube'
    },
    {
      icon: 'icon-facebook',
      link: URLS_MAP.facebook,
      text: 'Facebook'
    },
    {
      icon: 'icon-reddit',
      link: URLS_MAP.reddit,
      text: 'Reddit'
    },
]

export const resourcesMenus: SubMenu[] = [
  {
    icon: 'icon-gitbook',
    link: URLS_MAP.gitbook,
    text: 'Gitbook'
  },
  {
    icon: 'icon-a-Auditreports',
    link: URLS_MAP.auditReport,
    text: 'Audit Report'
  },
  {
    icon: 'icon-xingzhuang',
    link: URLS_MAP.mediaKit,
    text: 'Media Kit'
  },
]

export default links