import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './utils/resize';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { ChakraProvider } from '@chakra-ui/react';
// import { extendTheme } from "@chakra-ui/react"

// const theme = extendTheme({
//   styles: {
//     global: {
//       // styles for the `body`
//       body: {
//         bg: "#0E1124",
//         // color: "white",
//         // fontFamily: 'serif, sans-serif',
//       },
//     },
//   },
// });

ReactDOM.render(
  <React.StrictMode>
    {/* <ChakraProvider theme={theme}> */}
      <App />
    {/* </ChakraProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
