import investor1 from './assets/images/investor1.png';
import investor2 from './assets/images/investor2.png';
import investor3 from './assets/images/investor3.png';
import investor4 from './assets/images/investor4.png';
import investor5 from './assets/images/investor5.png';
import investor6 from './assets/images/investor6.png';
import investor7 from './assets/images/investor7.png';
import investor8 from './assets/images/investor8.png';
import investor9 from './assets/images/investor9.png';
import investor10 from './assets/images/investor10.png';
import investor11 from './assets/images/investor11.png';
import investor12 from './assets/images/investor12.png';
import investor13 from './assets/images/investor13.png';
import investor14 from './assets/images/investor14.png';
import investor15 from './assets/images/investor15.png';
import investor16 from './assets/images/investor16.png';
import investor17 from './assets/images/investor17.png';
import investor18 from './assets/images/investor18.png';
import investor19 from './assets/images/investor19.png';
import investor20 from './assets/images/investor20.png';
import investor21 from './assets/images/investor21.png';
import investor22 from './assets/images/investor22.png';
import investor23 from './assets/images/investor23.png';
import investor24 from './assets/images/investor24.png';
import investor25 from './assets/images/investor25.png';
import investor26 from './assets/images/investor26.png';
import investor27 from './assets/images/investor27.png';
import investor28 from './assets/images/investor28.png';
import investor29 from './assets/images/investor29.png';
import investor30 from './assets/images/investor30.png';
import investor31 from './assets/images/investor31.png';
import investor32 from './assets/images/investor32.png';
import investor33 from './assets/images/investor33.png';
import investor34 from './assets/images/investor34.png';
import investor35 from './assets/images/investor35.png';
import investor36 from './assets/images/investor36.png';
import investor37 from './assets/images/investor37.png';
import investor38 from './assets/images/investor38.png';
import investor39 from './assets/images/investor39.png';
import investor40 from './assets/images/investor40.png';
import investor41 from './assets/images/investor41.png';
import investor42 from './assets/images/investor42.png';
import InvestorPyth from './InvestorPyth';

interface Investor {
  imageSrc: string | React.FC;
}

const investors: Investor[] = [
  {imageSrc: investor1},
  {imageSrc: investor39},
  {imageSrc: investor2},
  {imageSrc: investor21},
  {imageSrc: investor8},

  {imageSrc: InvestorPyth},

  {imageSrc: investor3},
  {imageSrc: investor4},
  {imageSrc: investor5},
  {imageSrc: investor6},
  {imageSrc: investor7},
  {imageSrc: investor9},
  {imageSrc: investor10},
  {imageSrc: investor11},
  {imageSrc: investor12},
  {imageSrc: investor13},
  {imageSrc: investor14},
  {imageSrc: investor15},
  {imageSrc: investor16},
  {imageSrc: investor17},
  {imageSrc: investor18},
  {imageSrc: investor19},
  {imageSrc: investor20},
  {imageSrc: investor22},
  {imageSrc: investor23},
  {imageSrc: investor24},
  {imageSrc: investor25},
  {imageSrc: investor26},
  {imageSrc: investor27},
  {imageSrc: investor28},
  {imageSrc: investor29},
  {imageSrc: investor30},
  {imageSrc: investor31},
  {imageSrc: investor32},
  {imageSrc: investor33},
  {imageSrc: investor34},
  {imageSrc: investor35},
  {imageSrc: investor36},
  {imageSrc: investor37},
  {imageSrc: investor38},
  {imageSrc: investor40},
  {imageSrc: investor41},
  {imageSrc: investor42},
]

export default investors;