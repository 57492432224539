const URLS_MAP = {
  auditReport: 'https://linktr.ee/duetaudit',
  twitter: 'https://twitter.com/duetprotocol',
  discord: 'https://discord.gg/duetfinance',
  telegram: 'https://t.me/duetprotocol',
  medium: 'https://duetprotocol.medium.com/',
  facebook: 'https://www.facebook.com/duetprotocol',
  reddit: 'https://www.reddit.com/r/DuetProtocol/',
  youtube: 'https://www.youtube.com/c/duetprotocol',
  docs: 'https://duet-protocol.gitbook.io/duet-protocol/master/introduction',
  mail: 'mailto:contact@duet.finance',
  gitbook: 'https://duet-protocol.gitbook.io/duet-protocol/',
  mediaKit: 'https://drive.google.com/drive/folders/1Mdz0ZJ755oxjzXA4NP49mRmGJjgSSf33?usp=sharing'
};

export default URLS_MAP;
