import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import market from "./assets/images/duet-pro/market.png";
import upTo80 from "./assets/images/duet-pro/up-to-80.png";
import duetStaking from "./assets/images/duet-pro/duet-staking.png";
import hedge from "./assets/images/duet-pro/hedge.png";
import leverage from "./assets/images/duet-pro/leverage.png";
import classNames from "classnames";

interface Feature {
  row1: string;
  row2: string;
  picture: string;
}

const features: Feature[] = [
  {
    row1: "Global Asset Trading",
    row2: "Easy trading of US stocks and forex spot prices.",
    picture: market,
  },
  {
    row1: "$DUET and $bDUET Staking",
    row2: "Accelerated rewards through staking.",
    picture: duetStaking,
  },
  {
    row1: "Up to 80% Transaction Fee Rebates",
    row2: "Industry-leading rate for liquidity providers(BEST!)",
    picture: upTo80,
  },
  {
    row1: "Diversified Risk Hedging Mechanism",
    row2: "Effective risk management for users.",
    picture: hedge,
  },
  {
    row1: "Up to 100x Leverage",
    row2: "More investment opportunities for users.",
    picture: leverage,
  },
];

const V2Features: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentPicture = useMemo(() => {
    return features[currentIndex]?.picture || "";
  }, [currentIndex]);

  return (
    <>
      <V2FeaturesWrapperPc>
        <div className="left">
          {features.map((feature, index) => {
            return (
              <div
                className={classNames("feature-item", {
                  active: index === currentIndex,
                })}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              >
                <div className="row-1">{feature.row1}</div>
                <div className="row-2">{feature.row2}</div>
              </div>
            );
          })}
        </div>
        <div className="right">
          <img src={currentPicture} alt=""></img>
        </div>
      </V2FeaturesWrapperPc>

      <V2FeaturesWrapperMobile>
        {features.map((feature, index) => {
          return (
            <div className="feature-item">
              <div className="title">{feature.row1}</div>
              <div className="description">{feature.row2}</div>
              <img src={feature.picture} alt=""></img>
            </div>
          );
        })}
      </V2FeaturesWrapperMobile>
    </>
  );
};

export default V2Features;

const V2FeaturesWrapperPc = styled.div`
  @media (max-width: 950px) {
    display: none;
  }
  display: flex;

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: white;
    font-size: 14px;

    .feature-item {
      text-align: left;
      margin-top: 10px;
      opacity: 0.3;
      cursor: pointer;

      &.active {
        opacity: 1;
      }

      .row-1 {
        font-size: 24px;
        color: white;
      }

      .row-2 {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 480px;

    img {
      width: 640px;
    }
  }
`;

const V2FeaturesWrapperMobile = styled.div`
  display: none;
  @media (max-width: 950px) {
    display: block;
  }

  .feature-item {
    margin-top: 20px;
  }

  text-align: left;

  .title {
    font-size: 18px;
    color: white;
  }

  .description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 5px;
  }

  img {
    width: 90%;
    margin: 10px auto;
  }
`;
