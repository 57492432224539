import styled from "@emotion/styled";
import { FC, useState } from "react";
import { roadMaps } from "./roadMaps";

const RoadMap: FC = () => {
  const [showAllRoadMap, setShowAllRoadMap] = useState(false);
  const filteredRoadMaps = roadMaps
    .reduce((a, c) => [c, ...a], [] as any[])
    .slice(0, showAllRoadMap ? -1 : 5);

  return (
    <>
      <RoadMapWrapper>
        {filteredRoadMaps.map((each, index) => (
          <div className="roadmap-block">
            <div className="left">
              {index % 2 === 0 && (
                <>
                  <div className="title">{each.time}</div>
                  <div
                    className="content"
                    style={{
                      opacity: each.done ? 0.4 : 1,
                    }}
                  >
                    {each.children.map((row: any) => (
                      <div className="content-row">{row}</div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="timeline">
              <div className="circle"></div>
              <div className="line"></div>
            </div>
            <div className="right">
              {index % 2 === 1 && (
                <>
                  <div className="title">{each.time}</div>
                  <div
                    className="content"
                    style={{
                      opacity: each.done ? 0.4 : 1,
                    }}
                  >
                    {each.children.map((row: any) => (
                      <div className="content-row">{row}</div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        <button onClick={() => setShowAllRoadMap(!showAllRoadMap)} className="expand">
          {showAllRoadMap ? "Unfold" : "See All Roadmap"}
        </button>
      </RoadMapWrapper>
      <RoadMapWrapperMobile>
        {roadMaps.map((each) => (
          <div className="road-wrapper">
            <div className="road-period">{each.time}</div>
            <div className="road-map-content">
              {each.children.map((text) => {
                return <div className="road-text">{text}</div>;
              })}
            </div>
          </div>
        ))}
      </RoadMapWrapperMobile>
    </>
  );
};

export default RoadMap;

const RoadMapWrapper = styled.div`
  .roadmap-block {
    display: flex;
    min-height: 140px;
  }

  .left,
  .right {
    width: calc(50% - 5px);
    padding: 0 20px 20px;

    .title {
      font-size: 14px;
      color: #613bf4;
    }

    .content {
      font-size: 16px;
      color: #ffffff;
      opacity: 0.4;
    }
  }

  .left {
    text-align: right;
  }

  .right {
    text-align: left;
  }

  .timeline {
    width: 20px;

    .circle {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: #1d1249;

      :after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #613bf4;
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }

    .line {
      width: 2px;
      height: 100%;
      margin-left: 9px;
      background-color: #613bf4;
      margin-top: 2px;
    }
  }

  .expand {
    width: 240px;
    height: 40px;
    border: 1px solid #613bf4;
    margin-top: 50px;
    font-size: 16px;
    line-height: 38px;
    font-weight: bold;
    color: #613bf4;
    background-color: transparent;
    cursor: pointer;

    :hover {
      background: #613bf4;
      color: white;
    }
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

const RoadMapWrapperMobile = styled.div`
  text-align: left;
  display: none;

  @media (max-width: 950px) {
    display: block;
  }

  .road-wrapper {
    .road-period {
      font-size: 0.4rem;
      font-family: Inter-Bold, Inter;
      font-weight: bold;
      color: #613bf4;
      margin-top: 0.7rem;
    }

    .road-map-content {
      font-size: 0.28rem;
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      color: #9195a5;

      .road-text {
        margin-top: 0.2rem;
        padding-left: 0.4rem;
        position: relative;

        &:after {
          content: "";
          width: 0.12rem;
          height: 0.12rem;
          background: #613bf4;
          opacity: 1;
          border-radius: 0.06rem;
          position: absolute;
          top: 0.15rem;
          left: 0.02rem;
        }
      }
    }
  }
`;
