import styled from "styled-components";
import logo from "./assets/svg/logo.svg";
// import logoMobile from './logo-mobile.svg'
import logoMobile from "./assets/logo512.png";
// import brand from './assets/svg/brand.svg';
import banner from "./assets/images/banner.png";
import subtitle from "./assets/images/subtitle.png";

import hotSVG from "./assets/svg/hot.svg";
// import { useSize } from "ahooks";

import React, { useEffect } from "react";
import qs from "query-string";
// import howWork from "./assets/svg/how-it-works.svg";
import { communityMenus, resourcesMenus, SubMenu } from "./linkList";
import reportRefAddress from "./reportRefAddress";
import Wave from "./Wave/Wave";
import { Popover } from "antd";
import classNames from "classnames";
import Margin from "./components/Margin";
import Footer from "./Footer";
import InvestorPart from "./InvestorPart";
import HowItWorks from "./HowItWorks";
import RecentActivity from "./RecentActivity";
import V2Features from "./V2Features";
import DuetProV2 from "./DuetProV2";
import TokenDistribution from "./TokenDistribution";
import RoadMap from "./RoadMap";
import { ContentTitle } from "./components/ContentTitle";
import AboutV1 from "./AboutV1";

const Body = styled.div`
  text-align: center;
  /* background-color: #616857; */
  max-width: 1280px;
  margin: 0 auto;
  font-size: 0;

  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 950px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }

  .background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
`;

const FirstPage = styled.div`
  height: 100vh;
`;

const HeaderView = styled.header`
  height: 120px;
  display: flex;
  align-items: center;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    justify-content: center;
    height: auto;
    padding-top: 40px;
  }
`;

const DuetLogo = styled.img`
  height: 40px;
  cursor: pointer;

  @media (max-width: 950px) {
    display: none;
  }
`;

const DuetLogoMobile = styled.img`
  display: none;

  @media (max-width: 950px) {
    display: block;
    height: 1.8rem;
  }
`;

const OriMenuList = styled.nav`
  display: flex;
  margin-left: auto;

  @media (max-width: 950px) {
    margin-bottom: 40px;

    /* display: none; */
    .community,
    .resource {
      width: 90px;
      height: 24px;
      line-height: 25px;
      border-radius: 15px 15px 15px 15px;
      font-size: 12px;
      color: white;
      margin-right: 20px;
      padding: 0 10px;
      cursor: pointer;
      user-select: none;
    }
  }
`;

const DuetIntro = styled.div`
  position: relative;
  font-size: 0;

  @media (max-width: 950px) {
    box-sizing: border-box;
  }

  .subtitle {
    width: 787px;
    height: 23px;
    margin-top: 57px;

    @media (max-width: 950px) {
      box-sizing: border-box;
      display: none;
    }
  }
`;

const DuetIntroMobile = styled.div`
  font-size: 19px;
  font-family: Teko-Regular, Teko;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
  margin-top: 30px;
  font-weight: 500;
  display: none;

  @media (max-width: 950px) {
    box-sizing: border-box;
    display: block;
  }
`;

const Brand = styled.div`
  img {
    height: 80px;

    @media (max-width: 950px) {
      height: 0.76rem;
    }
  }
`;

const HowWork = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WhatIsDuet = styled.div`
  /* flex: 1; */
  font-size: 20px;
  font-weight: 400;
  font-family: poppinsRegular;
  /* background: green; */
  /* max-width: 640px; */
  text-align: left;
  color: #9195a5;
  margin-left: 20px;

  @media (max-width: 950px) {
    margin-left: 0;
    font-size: 0.3rem;
    line-height: 0.48rem;
    width: auto;
  }
`;

export const KFimage = styled.div`
  @media (max-width: 950px) {
    display: none;
  }
`;

const Space = styled.div<{ width?: number; height?: number; mWidth?: number; mHeight?: number }>`
  width: ${({ width }) => (width ? width + "px" : "0px")};
  height: ${({ height }) => (height ? height + "px" : "0px")};

  @media (max-width: 950px) {
    width: ${({ mWidth }) => (mWidth ? mWidth + "rem" : "0px")};
    height: ${({ mHeight }) => (mHeight ? mHeight + "rem" : "0px")};
  }
`;

const Community = styled.div`
  width: 180px;
  height: 48px;
  background: rgba(85, 116, 255, 0.3);
  border-radius: 30px 30px 30px 30px;
  font-size: 16px;
  color: white;
  line-height: 50px;
  margin-right: 40px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #5574ff;
  }
`;

const GetStartButton = styled.div`
  height: 48px;
  line-height: 48px;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 2px solid #5574ff;
  cursor: pointer;
  font-size: 16px;
  color: white;
  line-height: 44px;
  user-select: none;
  padding: 0 14px;

  &:hover {
    border: none;
    background: #5574ff;
    line-height: 48px;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

const GetStartButtonMobile = styled.div`
  display: none;
  height: 48px;
  line-height: 48px;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 2px solid #5574ff;
  cursor: pointer;
  font-size: 16px;
  color: white;
  line-height: 44px;
  user-select: none;
  padding: 0 14px;

  &:hover {
    border: none;
    background: #5574ff;
    line-height: 48px;
  }

  @media (max-width: 950px) {
    display: block;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #5574ff;
    font-size: 12px;
    padding: 0 10px;

    &:hover {
      border: none;
      background: #5574ff;
      line-height: 24px;
    }
  }
`;

const Resources = Community;
// const DuetProCloud = Community;

const SubMenuWrapper = styled.div`
  width: 180px;
  background: #0e1124;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .menu-item {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 20px;

    &:hover {
      background: rgba(85, 116, 255, 0.2);
    }

    i.iconfont {
      font-size: 24px;
      color: #ffffff;
    }

    .menu-item-text {
      font-size: 16px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      margin-left: 20px;
    }
  }
`;

const ColorfulButton = styled.div`
  width: 230px;
  height: 80px;
  line-height: 80px;
  background: linear-gradient(90deg, #5574ff 0%, #af0bd9 100%);
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  position: relative;

  &:hover {
    background: linear-gradient(90deg, #7890ff 0%, #bf3ce1 100%);
  }

  @media (max-width: 950px) {
    margin-top: 20px;
  }
`;

// const ArbitrumPopoverContent = styled.div`
//   background: white;
//   border-radius: 8px;
//   padding: 10px;
// `;

const HotImg = styled.img`
  position: absolute;
  top: -10px;
  right: 0;
`;

export default function App() {
  // const size = useSize(document.querySelector("body"));

  // const isMobile = size && size.width <= 950;

  useEffect(() => {
    const search = window.location.search;
    if (!search) return;
    const params = qs.parse(search);
    console.log(`params`, params);
    if (params.address && typeof params.address === "string") {
      reportRefAddress(params.address);
    }
  }, []);

  const renderSubMenu = (subMenus: SubMenu[]) => {
    return (
      <SubMenuWrapper>
        {subMenus.map((menu) => {
          return (
            <a className="menu-item" href={menu.link} target="_blank" rel="noreferrer">
              <i className={classNames("iconfont", menu.icon)}></i>
              <span className="menu-item-text">{menu.text}</span>
            </a>
          );
        })}
      </SubMenuWrapper>
    );
  };

  const goToDuetProCloud = () => {
    window.open("https://app.duet.finance/duet-pro-cloud", "_blank");
  };

  const goToDuetApp = () => {
    window.open("https://app.duet.finance/bond", "_blank");
  };

  return (
    <Body>
      <FirstPage>
        <Wave></Wave>
        <HeaderView>
          <DuetLogo src={logo} alt="logo" />
          <DuetLogoMobile src={logoMobile} alt="logo" />
          <OriMenuList>
            <Popover
              getPopupContainer={(trigger) => trigger.parentElement!}
              content={renderSubMenu(communityMenus)}
              trigger="hover"
              placement="bottom"
              // overlayClassName="popover-no-arrow"
            >
              <Community className="community">Community</Community>
            </Popover>
            <Popover
              getPopupContainer={(trigger) => trigger.parentElement!}
              content={renderSubMenu(resourcesMenus)}
              trigger="hover"
              placement="bottom"
              // overlayClassName="popover-no-arrow"
            >
              <Resources className="resource">Resources</Resources>
            </Popover>
            <GetStartButton className="get-start" onClick={goToDuetApp}>
              bDUET: Let Your $DUET Soar
            </GetStartButton>
            <GetStartButtonMobile className="get-start" onClick={goToDuetApp}>
              bDuet
            </GetStartButtonMobile>
            {/* <WebApp onClick={goDuet}>
              APP
            </WebApp> */}
          </OriMenuList>
        </HeaderView>

        <Space height={146} mHeight={1.6} />
        <DuetIntro>
          <Brand>
            <img src={banner} alt="brand" />
          </Brand>
          <img className="subtitle" src={subtitle} alt="" />
          <DuetIntroMobile>
            A Parallel Universe Which Turns Flat Assets Into Sharp Assets
          </DuetIntroMobile>
          <Margin top={50}></Margin>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {/* <ColorfulButton onClick={goToDuetApp}>Duet App</ColorfulButton>
            <Margin left={50}></Margin> */}
            {/* <Popover
              getPopupContainer={(trigger) => trigger.parentElement!}
              content={
                <ArbitrumPopoverContent>100X synths trading on Arbitrum!</ArbitrumPopoverContent>
              }
              trigger="hover"
              placement="top"
              // overlayClassName="popover-no-arrow"
            > */}
            <div>
              <ColorfulButton onClick={goToDuetProCloud}>
                <HotImg src={hotSVG} alt="" />
                Duet Pro Cloud
              </ColorfulButton>
            </div>

            <Link href="https://pro.duet.finance/" target="_blank" rel="noreferrer">
              Try Duet Pro
            </Link>
          </div>
        </DuetIntro>
      </FirstPage>
      <Space height={100} mHeight={3.06} />
      <ContentTitle>
        <span>Recent Activity</span>
      </ContentTitle>
      <RecentActivity></RecentActivity>
      <Space height={100} mHeight={3.06} />
      <div>
        <ContentTitle>
          <span>What is Duet Protocol</span>
        </ContentTitle>
        <Margin top={80}></Margin>
        <WhatIsDuet>
          <p>
            Welcome to Duet—a parallel universe created for the digital asset world. Duet is an
            innovative decentralized protocol that transforms ordinary assets into sharp assets. Our
            vision is to enable all Web3 users to participate in various types of global asset
            trading in a simpler and more efficient way by creating and providing synthetic assets.
            As the only on-chain perpetual contract trading platform for trading US stocks in the
            market, Duet Pro(Duet V2) opens the door to higher returns for liquidity providers with
            its convenient features for trading US stocks and forex spot, up to 80% trading fee
            rebate rate, and diversified risk hedging mechanisms.
          </p>
        </WhatIsDuet>
      </div>
      <Margin top={80}></Margin>
      <div>
        <ContentTitle>
          <span>Duet Pro(V2)</span>
        </ContentTitle>
        <img src="" alt="" />
        <Margin top={80}></Margin>
        <DuetProV2></DuetProV2>
      </div>
      <Space height={106} mHeight={1.8} />
      <HowWork>
        <ContentTitle>
          <span>How It Works</span>
        </ContentTitle>
        <Margin top={20}></Margin>

        <HowItWorks></HowItWorks>
      </HowWork>
      <Space height={106} mHeight={1.8} />
      <HowWork>
        <ContentTitle>
          <span>$DUET Token Distribution Plan</span>
        </ContentTitle>
        <Margin top={20}></Margin>
        <TokenDistribution></TokenDistribution>
      </HowWork>
      <Space height={100} mHeight={1.8} />

      <ContentTitle>
        <span>
          Duet Pro(V2)<br></br>Key Advantages and Features
        </span>
      </ContentTitle>
      <V2Features></V2Features>
      <Space height={100} mHeight={1.8} />
      <AboutV1></AboutV1>

      <Space height={100} mHeight={1.8} />
      <ContentTitle>
        <span>Road Map</span>
      </ContentTitle>
      <RoadMap></RoadMap>
      <Space height={100} mHeight={1.8} />
      <ContentTitle>
        <span>Global Partners</span>
      </ContentTitle>
      <InvestorPart></InvestorPart>
      <Space height={100} mHeight={1.8} />
      {/* <InviteReferral /> */}
      <Footer></Footer>
    </Body>
  );
}

const Link = styled.a`
  color: white;
  text-decoration: underline;
  line-height: 1.5em;
  font-size: 20px;
  font-family: Poppins-Regular, Poppins;
  margin-top: 20px;
  font-weight: bold;
`;
