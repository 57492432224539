import { FC } from "react";
import workImg from "./assets/images/duet-pro/how-it-works.png";
import styled from "styled-components";

const HowItWorks: FC = () => (
  <HowItWorksWrapper>
    <img width="1280px" src={workImg} alt="how it works" />
  </HowItWorksWrapper>
);

export default HowItWorks;

export const HowItWorksWrapper = styled.div`
  img {
    @media (max-width: 950px) {
      width: 90%;
      margin: 0 auto;
    }
  }
`;
