import Axios from 'axios';
import qs from 'query-string'

const instance = Axios.create({
  baseURL: `https://api.ourinsight.app`,
  timeout: 60 * 1000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});



const reportRefAddress = (address: string) => {
  const referrer = window.localStorage.getItem('referrer')
  if (referrer) {
    return
  }

  instance.post('/api/introduce', qs.stringify({
    author: address,
    viewTime: ('' + new Date().getTime()).slice(0 , -3)
  })).then((res) => {
    console.log('res: ', res)
    if (res && res.data && res.data.code === 0) {
      window.localStorage.setItem('referrer', address)
    }
  })
}

export default reportRefAddress;