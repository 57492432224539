import { FC } from "react";
import styled from "styled-components";
import distributionPlanImg from "./assets/images/duet-pro/graph.png";

const TokenDistribution: FC = () => (
  <TokenDistributionWrapper>
    <img width="1280px" src={distributionPlanImg} alt="how it works" />
  </TokenDistributionWrapper>
);

export default TokenDistribution;

export const TokenDistributionWrapper = styled.div`
  img {
    @media (max-width: 950px) {
      width: 90%;
      margin: 0 auto;
    }
  }
`;
