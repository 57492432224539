import { ReactElement } from "react";
interface Feature {
  title: string | ReactElement;
  content: string;
}

const features: Feature[] = [
  {
    title: <span>Algo based <br/> hyper-collateralization model</span>,
    content: 'boosting capital utilization while enhancing system robustness'
  },
  {
    title: 'Hassle-free Diversification',
    content: 'diversify portfolio risks across asset classes in one crypto wallet'
  },
  {
    title: 'Yield Enhancer',
    content: 'maximize yield as a liquidity provider of any DeFi protocol （yToken, cToken, LP token, etc）'
  },
  {
    title: 'Market Maker Mechanism',
    content: 'risk-neutral mining for liquidity providers, maximize liquidity for investors'
  },
  {
    title: 'Duet Bond',
    content: 'lock-up highest return without compromising liquidity'
  },
  {
    title: 'Governed by DAO',
    content: 'delegate power to the users in a transparent & censorship-resistance way'
  }
]

export default features;