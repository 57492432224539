import { FC } from "react";
import investorPyth from "./assets/images/investor-pyth.png";
import styled from "styled-components";

const InvestorPyth: FC = () => (
  <InvestorPythWrapper>
    <img src={investorPyth} alt="investor-pyth" />
  </InvestorPythWrapper>
);

export default InvestorPyth;

const InvestorPythWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 200px;
  }
`;
