export const roadMaps = [
  {
    time: 'May 2021',
    children: [
      "Development of the Instant DUET/ dAsset Minter. (Complete)"
    ],
    done: true,
  },
  {
    time: 'June 2021',
    children: [
      "P1 financing closed. (Complete)"
    ],
    done: true,
  },
  {
    time: 'July 2021',
    children: [
      "Pilot product Zerogoki launched, focusing on leveraged synthetic tokens and gained over $5M in liquidity. zUSD ranked 3rd in volume and 4th in liquidity within the category of algorithm stable coins on #Uniswap. (Complete)",
      "Audit report for algo-minting contract. (Complete)",
    ],
    done: true,
  },
  {
    time: 'November 2021',
    children: [
      "Upgrade Zerogoki (REI and zAssets) to DUET(DUET and dAssets). (Complete)",
      "Launched dUSD minting module, a high capital efficiency synthetic stablecoin based on decentralized V-AMM mechanism. (Complete)"
    ],
    done: true,
  },
  {
    time: 'December 2021 - January 2022',
    children: [
      "Launched the over-collateralization module of Duet Protocol. (Complete)",
    ],
    done: true,
  },
  {
    time: 'January 2022',
    children: [
      "Token-denominated Bond as a Service deployed.",
      "Launch the security pool, to expand the DUET utility.",
      "Expand the accepted range of receipt tokens to include all major defi protocols. Establish lending markets for dAssets.",
    ],
    done: true,
  },
  {
    time: 'February 2022',
    children: [
      "Duet V1 fully audited (Complete)",
      "Launched on BSC, ETH, and Polygon (Complete)",
    ],
    done: true,
  },
  {
    time: 'March 2022',
    children: [
      "bDUET liquidity mining launched",
      "Joint liquidity mining with PancakeSwap (Complete)",
    ],
    done: true,
  },
  {
    time: 'April 2022',
    children: [
      "Token-denominated Bond as a Service deployed (Complete)",
    ],
    done: true,
  },
  {
    time: 'May 2022',
    children: [
      "ebCAKE strategy launched (Complete)",
    ],
    done: true,
  },
  {
    time: 'June 2022 - December 2022',
    children: [
      "Third-round security audit by Go+ and Certik (Complete)",
    ],
    done: true,
  },
  // {
  //   time: 'September 2022 - December 2022',
  //   children: [
  //     "Third-round security audit by Go+ and Certik (Complete)",
  //   ]
  // },
  {
    time: 'January 2023',
    children: [
      "Fiat-denominated Bond as a Service deployed. (Complete)",
    ],
    done: true,
  },
  {
    time: 'March 2023',
    children: [
      "Duet Pro on Arbitrum launched (Complete)",
    ],
    done: true,
  },
  {
    time: 'May 2023',
    children: [
      "RWA trading competitions.",
    ],
    done: true,
  },
  {
    time: 'June 2023',
    children: [
      "30+ RWA listing.",
    ],
    done: true,
  },
  {
    time: 'July 2023',
    children: [
      "Duet Pro Cloud service released.",
    ],
    done: true,
  },
  {
    time: 'September 2023',
    children: [
      "Duet Pro V2 launch.",
    ]
  },
  {
    time: 'December 2023',
    children: [
      "L2 derivative liquidity aggregator launch.",
    ]
  },
]