import styled from "styled-components";

export const ContentTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
  font-family: poppinsMedium;
  text-align: center;
  margin: 0 auto;
  max-width: 630px;
  min-height: 116px;

  @media (max-width: 950px) {
    font-size: 0.48rem;
    line-height: 0.72rem;
    min-height: 30px;
    text-align: left;
    color: white;
  }

  span {
    color: white;
    /* background: #d2cae1 linear-gradient(315deg, #543bf0 0%, #e9b5ff 100%); */
    /* background-clip: text; */
    /* -webkit-background-clip: text; */
    /* color: transparent; */

    @media (max-width: 950px) {
      color: white;
      background: none;
    }
  }
`;
