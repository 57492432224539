import styled from "@emotion/styled";
import { FC } from "react";
import keyfeature from "./assets/images/keyfeature.png";
import features from "./features";
import { ContentTitle } from "./components/ContentTitle";

const V1Features: FC = () => (
  <V1FeaturesWrapper>
    <KeyFeatureTitle>
      <span>V1 Key Features</span>
    </KeyFeatureTitle>
    <KeyFeature>
      <KFTxtBorder>
        {features
          .filter((each, index) => index < features.length / 2)
          .map((each) => (
            <>
              <TxtCircle>
                <KFContent>{each.title}</KFContent>
                <SmallCircle />
              </TxtCircle>
              <KFContentSmall>{each.content}</KFContentSmall>
            </>
          ))}
      </KFTxtBorder>

      <KFimage>
        <img src={keyfeature} alt="keyfeature" />
      </KFimage>

      <KFTxtBorder>
        {features
          .filter((each, index) => index >= features.length / 2)
          .map((each) => (
            <>
              <TxtCircle>
                <SmallCircle />
                <KFContent align="left" width={280}>
                  {each.title}
                </KFContent>
              </TxtCircle>
              <KFContentSmall align="left" left={50}>
                {each.content}
              </KFContentSmall>
            </>
          ))}
      </KFTxtBorder>
    </KeyFeature>
  </V1FeaturesWrapper>
);

export default V1Features;

const V1FeaturesWrapper = styled.div``;

export const KFimage = styled.div`
  @media (max-width: 950px) {
    display: none;
  }
`;

const TxtCircle = styled.div`
  position: relative;
  display: flex;
  margin-top: 20px;

  @media (max-width: 950px) {
    padding-left: 30px;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #5435ed;
      position: absolute;
      top: 50px;
      left: 0;
    }
  }
`;

const KFTxtBorder = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
`;

const KFContent = styled.div<{ align?: string; width?: number }>`
  color: #d7d6d6;
  font-size: 18px;
  font-family: poppinsRegular;
  font-weight: bold;
  text-align: ${({ align }) => (align ? align : "right")};
  width: ${({ width }) => (width ? width + "px" : "335px")};
  /* text-align: right;
  width: 335px; */

  @media (max-width: 950px) {
    margin-top: 0.8rem;
    text-align: left;
    width: auto;
    font-size: 0.4rem;
    line-height: 0.6rem;
  }
`;

const KFContentSmall = styled.div<{ align?: string; left?: number }>`
  color: #ffffff;
  font-size: 14px;
  font-family: poppinsLight;
  opacity: 0.5;
  /* text-align: right; */
  text-align: ${({ align }) => (align ? align : "right")};
  width: 280px;
  margin-left: ${({ left }) => (left ? left + "px" : "55px")};
  /* margin-left: 55px; */

  @media (max-width: 950px) {
    margin-left: 0;
    margin-top: 0.2rem;
    text-align: left;
    width: auto;
    font-size: 0.32rem;
    line-height: 0.48rem;
    padding-left: 30px;
  }
`;

const SmallCircle = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(270deg, #af0bd9 0%, #5574ff 100%);
  color: #707482;

  @media (max-width: 950px) {
    display: none;
  }
`;

const KeyFeatureTitle = styled(ContentTitle)`
  min-height: 116px;

  @media (max-width: 950px) {
    min-height: 30px;
  }
`;

const KeyFeature = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
