import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import Margin from "./components/Margin";
import investors from "./investors";

const InvestorPart: FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const showedInvestors = useMemo(() => {
    if (collapsed) {
      return investors.slice(0, 6);
    }
    return investors;
  }, [collapsed]);

  return (
    <InvestorPartWrapper>
      <PcPartners>
        {showedInvestors.map((investor) => {
          if (typeof investor.imageSrc === "string") {
            return <Investor src={investor.imageSrc} />;
          } else {
            return investor.imageSrc({});
          }
        })}
      </PcPartners>
      <MobilePartners>
        {showedInvestors.map((investor) => {
          if (typeof investor.imageSrc === "string") {
            return <Investor src={investor.imageSrc} />;
          } else {
            return investor.imageSrc({});
          }
        })}
      </MobilePartners>
      {collapsed && (
        <div className="show-more" onClick={() => setCollapsed(false)}>
          <span>More Investors</span>
          <Margin left={10}></Margin>
          <i className="iconfont icon-xiajiantou"></i>
        </div>
      )}
      {!collapsed && (
        <div className="show-more" onClick={() => setCollapsed(true)}>
          <span>Collapse</span>
          <Margin left={10}></Margin>
          <i className="iconfont icon-shangjiantou"></i>
        </div>
      )}
    </InvestorPartWrapper>
  );
};
export default InvestorPart;

const InvestorPartWrapper = styled.div`
  .show-more {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-family: Poppins-Medium, Poppins;
    font-weight: 500;
    color: #5574ff;
    cursor: pointer;
    user-select: none;

    /* @media (max-width: 950px) {
      display: none;
    } */
  }
`;

const Investor = styled.img`
  margin-bottom: 20px;
  width: 400px;
`;

const PcPartners = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0;

  @media (max-width: 950px) {
    display: none;
  }
`;

const MobilePartners = styled.div`
  display: none;

  @media (max-width: 950px) {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 45%;
    }
  }
`;
