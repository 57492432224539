import styled from "@emotion/styled";
import { FC, useState } from "react";
import showMore from "./assets/images/show-more.png";
import V1Features from "./V1Features";
import { ContentTitle } from "./components/ContentTitle";
import Margin from "./components/Margin";

const AboutV1: FC = () => {
  const [showV1, setShowV1] = useState(false);

  return (
    <AboutV1Wrapper>
      {!showV1 && (
        <div onClick={() => setShowV1(true)}>
          <div className="about-v1">About V1</div>
          <img className="show-more" src={showMore} alt="" />
        </div>
      )}
      {showV1 && (
        <>
          <ContentTitle>
            <span>About V1</span>
          </ContentTitle>
          <div className="about-v1-text">
            Duet is the world's first multi-chain synthetic asset protocol with a hybrid mechanism
            (over-collateralization + Algo-pegged) that "sharpens" all assets to be traded on the
            blockchain. Duet enables traders to replicate real-world tradable assets in a
            decentralized finance ecosystem.
          </div>
          <Margin top={80}></Margin>
          <V1Features></V1Features>
        </>
      )}
    </AboutV1Wrapper>
  );
};

export default AboutV1;

const AboutV1Wrapper = styled.div`
  cursor: pointer;

  .about-v1-text {
    font-size: 26px;
    color: #d2d2d2;
    text-align: left;
  }

  .about-v1 {
    color: rgba(85, 116, 255, 1);
    font-size: 60px;
    margin: 0 auto;
    font-weight: bold;
  }
  .show-more {
    margin: 0 auto;
    width: 40px;
    height: 40px;
  }
`;
