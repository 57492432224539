import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  top?: number;
  left?: number;
}

const Margin: FC<Props> = props => {
  const { top, left } = props;
  return <MarginWrapper top={top} left={left} />;
};

export default Margin;

const MarginWrapper = styled.div<Props>`
  display: block;
  content: '';
  margin-top: ${props => (props.top ? `${props.top}px` : 0)};
  margin-left: ${props => (props.left ? `${props.left}px` : 0)};
`;
