import { FC } from "react";
import styled from "styled-components";

const RecentActivity: FC = () => (
  <RecentActivityWrapper>
    <div className="img-row">
      <div className="tweet">
        <blockquote className="twitter-tweet" data-theme="dark">
          <p lang="en" dir="ltr">
            0/🧵We just published Duet Protocol Global Market Recap and Outlook — 20230807 👉
            <a href="https://t.co/DrXSYtoBOa">https://t.co/DrXSYtoBOa</a>
          </p>
          &mdash; Duet Protocol (@duetprotocol){" "}
          <a href="https://twitter.com/duetprotocol/status/1688417339417513986?ref_src=twsrc%5Etfw">
            August 7, 2023
          </a>
        </blockquote>{" "}
      </div>

      <div className="tweet">
        <blockquote className="twitter-tweet" data-theme="dark">
          <p lang="en" dir="ltr">
            1/🚀Exciting news from{" "}
            <a href="https://twitter.com/hashtag/DuetPro?src=hash&amp;ref_src=twsrc%5Etfw">
              #DuetPro
            </a>
            ! We are thrilled to introduce Duet Pro Cloud, a revolutionary infrastructure that
            enables partners to build on our secure, advanced tech for digital asset exchanges.
            Visit here: <a href="https://t.co/qJQWJTjRt0">https://t.co/qJQWJTjRt0</a> 🔥{" "}
            <a href="https://t.co/FmwdIZ4JIQ">pic.twitter.com/FmwdIZ4JIQ</a>
          </p>
          &mdash; Duet Protocol (@duetprotocol){" "}
          <a href="https://twitter.com/duetprotocol/status/1687318751300206592?ref_src=twsrc%5Etfw">
            August 4, 2023
          </a>
        </blockquote>{" "}
      </div>

      <div className="tweet">
        <blockquote className="twitter-tweet" data-theme="dark">
          <p lang="en" dir="ltr">
            🚀 Introducing our latest Airdrop innovation: Get ready for a revolutionary experience
            with double personalized airdrops! 🎁🎁
            <a href="https://t.co/g9ITga9ovT">https://t.co/g9ITga9ovT</a>
            <br />
            <br />
            🎯 Your rewards, your way! Our on-chain data analytics will determine your personalized
            airdrop amount and curate exciting…
          </p>
          &mdash; Duet Protocol (@duetprotocol){" "}
          <a href="https://twitter.com/duetprotocol/status/1686785252470210561?ref_src=twsrc%5Etfw">
            August 2, 2023
          </a>
        </blockquote>{" "}
      </div>
    </div>
  </RecentActivityWrapper>
);

export default RecentActivity;

const RecentActivityWrapper = styled.div`
  .tweet {
    width: 25%;

    @media (max-width: 950px) {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  .img-row {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (max-width: 950px) {
      flex-direction: column;
    }

    img {
      width: 360px;
      height: 350px;
    }

    .p2 {
      width: 483px;
      height: 470px;
      position: relative;
      top: 20px;
    }
  }
`;
