import styled from "@emotion/styled";
import { FC } from "react";
import Margin from "./components/Margin";
import { WhatIsDuet } from "./App";

const DuetProV2: FC = () => (
  <DuetProV2Wrapper>
    <iframe src="https://pro.duet.finance" width="1200" height="800" title="MyFrame"></iframe>
    <Margin top={80}></Margin>
    <WhatIsDuet>
      <p>
        Duet Pro is the flagship product of Duet, being the only on-chain perpetual contract trading
        platform in the market that allows trading of US stocks. It provides liquidity providers
        with a new way to enhance their returns. By offering industry-leading trading fee rebate
        rates (up to 80%) and diversified risk hedging mechanisms, Duet Pro makes liquidity
        provision more attractive.
      </p>
    </WhatIsDuet>
    <Margin top={40}></Margin>
    <TryDuetProV2 href="https://pro.duet.finance/" target="_blank">
      Try More
    </TryDuetProV2>
  </DuetProV2Wrapper>
);

export default DuetProV2;

const DuetProV2Wrapper = styled.div`
  iframe {
    @media (max-width: 950px) {
      width: 100%;
      height: 100vh;
    }
  }
`;

const TryDuetProV2 = styled.a`
  display: inline-block;
  width: 280px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: rgba(97, 59, 244, 1);
  border-radius: 40px;
  color: white;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;

  &:hover {
    color: white;
  }
`;
