import classNames from "classnames";
import { FC } from "react";
import styled from "styled-components";
import { communityMenus } from "./linkList";

const links = [
  {
    text: "Privacy policy",
    url: "https://duet-protocol.gitbook.io/duet-protocol/library/privacy-policy",
  },
  {
    text: "Terms of service",
    url: "https://duet-protocol.gitbook.io/duet-protocol/library/terms-of-service",
  },
  {
    text: "Cookie policy",
    url: "https://duet-protocol.gitbook.io/duet-protocol/library/cookie-policy",
  },
];

const Footer: FC = () => (
  <FooterWrapper>
    <div className="left-area">
      <div className="top">
        {communityMenus.map((menu) => (
          <a target="_blank" href={menu.link} className="menu-item" rel="noreferrer">
            <i className={classNames("iconfont", menu.icon)}></i>
          </a>
        ))}
      </div>
      <div className="bottom">Copyright © 2022 Duet . All rights reserved.</div>
    </div>

    <div className="right-area">
      {links.map((each) => (
        <a target="_blank" rel="noreferrer" href={each.url} className="link">
          {each.text}
        </a>
      ))}
    </div>
  </FooterWrapper>
);

export default Footer;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  @media (max-width: 950px) {
    justify-content: center;
  }

  .left-area {
    .top {
      @media (max-width: 950px) {
        display: flex;
        justify-content: center;
      }

      .menu-item {
        padding: 10px;

        i.iconfont {
          font-size: 24px;
          color: #9195a5;

          &:hover {
            color: white;
          }
        }
      }
    }
    .bottom {
      margin-top: 6px;
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 16px;
    }
  }

  .right-area {
    display: flex;
    align-items: flex-end;

    .link {
      font-size: 14px;
      font-family: Poppins-Regular, Poppins;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 16px;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        color: white;
      }
    }

    @media (max-width: 950px) {
      display: none;
    }
  }
`;
